// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { createGift, clearStatus } from "redux/actions/gift";

// constants
import { mattressList } from "constants/products";
import { provinceList } from "constants/provinces";

// component
import GiftProductFormItem from "./GiftProductFormItem";

// style
import "./style.scss";

const GiftCreate = (props) => {
  const giftLabelList = props.giftLabelList;

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isShippEnabled, setIsShippEnabled] = useState(false);
  const [isShipp, setIsShipp] = useState(false);
  const [currentPostalCode, setCurrentPostalCode] = useState("");
  const [currentProvince, setCurrentProvince] = useState("");
  const [isShowNewLabelForm, setIsShowNewLabelForm] = useState(false);

  const candidateDeliveryDate = useSelector(
    (state) => state.gift.candidateDeliveryDate
  );
  const candidateDeliveryDateEnd = useSelector(
    (state) => state.gift.candidateDeliveryDateEnd
  );
  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.gift.isProcessing);
  const isCreateComplete = useSelector((state) => state.gift.isCreateComplete);

  const dispatch = useDispatch();

  const FIELDS = {
    name: "name",
    postalCode: "postal_code",
    province: "province",
    cityAddressLine: "city_address_line",
    phoneNumber: "phone_number",
    label: "label",
    memo: "memo",
    isShowNewLabelForm: "is_show_new_label_form",
    shipp: "shipp",
  };

  const PRODUCT_FIELDS = {
    quantity: "quantity",
    shipmentID: "shipment_id",
    trackingCode: "tracking_code",
    deliveryDate: "delivery_date",
    deliveryTime: "delivery_time",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyAddressFields = Object.values(FIELDS).every((field) => {
      switch (field) {
        case FIELDS.name:
        case FIELDS.postalCode:
        case FIELDS.province:
        case FIELDS.cityAddressLine:
        case FIELDS.phoneNumber:
          return elements[field].value;
        case FIELDS.label:
        case FIELDS.memo:
        case FIELDS.isShowNewLabelForm:
          return true;
        default:
          return true;
      }
    });

    setIsShippEnabled(isNoEmptyAddressFields);

    let isNoEmptyProductFields = true;

    if (elements[FIELDS.shipp].checked) {
      const isAllEmpty = mattressList.every((productInfo) => {
        const field = productInfo.productID + "_" + PRODUCT_FIELDS.quantity;
        return field in elements ? parseInt(elements[field].value) === 0 : true;
      });
      isNoEmptyProductFields = !isAllEmpty;
    }

    setIsSubmitEnabled(isNoEmptyAddressFields && isNoEmptyProductFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      name: elements[FIELDS.name].value,
      postal_code: elements[FIELDS.postalCode].value,
      province: elements[FIELDS.province].value,
      city_address_line: elements[FIELDS.cityAddressLine].value,
      phone_number: elements[FIELDS.phoneNumber].value,
      label: elements[FIELDS.label].value,
      memo: elements[FIELDS.memo].value,
      product_list: [],
    };

    mattressList.forEach((productInfo) => {
      const quantityField =
        productInfo.productID + "_" + PRODUCT_FIELDS.quantity;
      const quantity =
        quantityField in elements ? elements[quantityField].value : 0;

      if (quantity !== 0) {
        for (let i = 0; i < quantity; i++) {
          const deliveryDateField =
            productInfo.productID + "_" + PRODUCT_FIELDS.deliveryDate;
          const deliveryTimeField =
            productInfo.productID + "_" + PRODUCT_FIELDS.deliveryTime;
          data.product_list.push({
            openlogi_product_id: productInfo.productID,
            delivery_date: elements[deliveryDateField]
              ? elements[deliveryDateField].value
              : null,
            delivery_time: elements[deliveryTimeField]
              ? elements[deliveryTimeField].value
              : null,
          });
        }
      }
    });

    if (token) {
      dispatch(createGift(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isCreateComplete]);

  return (
    <div className="gift_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>氏名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.name}
                  disabled={isShipp}
                />
              </td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.postalCode}
                  onChange={(e) => setCurrentPostalCode(e.target.value)}
                  disabled={isShipp}
                />
              </td>
            </tr>
            <tr>
              <th>都道府県</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.province}
                  onChange={(e) => setCurrentProvince(e.target.value)}
                  disabled={isShipp}
                >
                  {provinceList.map((province) => (
                    <option value={province} key={province}>
                      {province}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>都道府県以降の住所</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.cityAddressLine}
                  disabled={isShipp}
                />
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.phoneNumber}
                  disabled={isShipp}
                />
              </td>
            </tr>
            <tr>
              <th>ラベル</th>
              <td>
                <input
                  className=""
                  name={FIELDS.is_show_new_label_form}
                  type="checkbox"
                  onChange={(e) => setIsShowNewLabelForm(e.target.checked)}
                />
                新規ラベルを登録
                <div className="label_form">
                  {isShowNewLabelForm ? (
                    <input
                      className="design_input"
                      type="text"
                      name={FIELDS.label}
                    />
                  ) : (
                    <select
                      className="design_select"
                      name={FIELDS.label}
                      defaultValue=""
                    >
                      <option value="">-</option>
                      {giftLabelList.map((label, index) => (
                        <option value={label} key={index}>
                          {label}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <th>メモ</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.memo}
                  disabled={isShipp}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <label>
          <input
            type="checkbox"
            name={FIELDS.shipp}
            onChange={(e) => setIsShipp(e.target.checked)}
            disabled={!isShippEnabled}
          />
          登録と同時に配送
        </label>
        {isShipp && (
          <>
            {mattressList.map((productInfo, index) => (
              <GiftProductFormItem
                postalCode={currentPostalCode}
                province={currentProvince}
                candidateDeliveryDate={
                  productInfo.productID in candidateDeliveryDate
                    ? candidateDeliveryDate[productInfo.productID]
                    : null
                }
                candidateDeliveryDateEnd={
                  productInfo.productID in candidateDeliveryDateEnd
                    ? candidateDeliveryDateEnd[productInfo.productID]
                    : null
                }
                productInfo={productInfo}
                key={index}
              />
            ))}
          </>
        )}
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default GiftCreate;
