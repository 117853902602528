export const ORDER_STATUS = {
  cancel: "cancel", // キャンセル済み
  returned: "returned", // 返品済み
  partiallyReturned: "partially_returned", // 一部返品済み
  returning: "returning", // 返品中
  shipped: "shipped", // 発送済み
  unshipped: "unshipped", // 未発送
  partiallyShipped: "partially_shipped", // 一部発送済み
};

export const convertOrderStatus = (status) => {
  switch (status) {
    case ORDER_STATUS.cancel:
      return "キャンセル済み";
    case ORDER_STATUS.returned:
      return "返品済み";
    case ORDER_STATUS.partiallyReturned:
      return "一部返品済み";
    case ORDER_STATUS.returning:
      return "返品中";
    case ORDER_STATUS.shipped:
      return "発送済み";
    case ORDER_STATUS.unshipped:
      return "未発送";
    case ORDER_STATUS.partiallyShipped:
      return "一部発送済み";
    default:
      return "";
  }
};

export const ORDER_ITEM_STATUS = {
  shipped: "shipped", // 発送済み
  unshipped: "unshipped", // 未発送
  returned: "returned", // 返品済み
  cancel: "cancel", // キャンセル済み
};

export const convertOrderItemStatus = (status) => {
  switch (status) {
    case ORDER_ITEM_STATUS.cancel:
      return "キャンセル済み";
    case ORDER_ITEM_STATUS.unshipped:
      return "未発送";
    case ORDER_ITEM_STATUS.returned:
      return "返品済み";
    case ORDER_ITEM_STATUS.shipped:
      return "発送済み";
    default:
      return "";
  }
};
