// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import { createGiftProduct, clearStatus } from "redux/actions/gift";

// constant
import { mattressList, mattressQueen, mattressKing } from "constants/products";

// component
import GiftProductFormItem from "../../Gift/GiftCreate/GiftProductFormItem";

// style
import "./style.scss";

const GiftProductCreate = (props) => {
  const { id, postal_code, province } = props.gift;

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const candidateDeliveryDate = useSelector(
    (state) => state.gift.candidateDeliveryDate
  );
  const candidateDeliveryDateEnd = useSelector(
    (state) => state.gift.candidateDeliveryDateEnd
  );
  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.gift.isProcessing);
  const isCreateComplete = useSelector((state) => state.gift.isCreateComplete);

  const dispatch = useDispatch();

  const FIELDS = {
    deliveryDate: "delivery_date",
    deliveryTime: "delivery_time",
    shipmentID: "shipment_id",
    trackingCode: "tracking_code",
    quantity: "quantity",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isAllEmptyQuantityFields = mattressList.every((productInfo) => {
      const field = productInfo.productID + "_" + FIELDS.quantity;
      return parseInt(elements[field].value) === 0;
    });

    setIsSubmitEnabled(!isAllEmptyQuantityFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const isKingQueen = (productID) => {
    return [mattressQueen.productID, mattressKing.productID].includes(
      productID
    );
  };

  const create = () => {
    let data = {
      gift_id: id,
      product_list: [],
    };

    mattressList.forEach((productInfo) => {
      const quantity =
        elements[productInfo.productID + "_" + FIELDS.quantity].value;

      if (quantity !== 0) {
        for (let i = 0; i < quantity; i++) {
          data.product_list.push({
            openlogi_product_id: productInfo.productID,
            delivery_date: elements[
              productInfo.productID + "_" + FIELDS.deliveryDate
            ]
              ? elements[productInfo.productID + "_" + FIELDS.deliveryDate]
                  .value
              : null,
            delivery_time: elements[
              productInfo.productID + "_" + FIELDS.deliveryTime
            ]
              ? elements[productInfo.productID + "_" + FIELDS.deliveryTime]
                  .value
              : null,
          });
        }
      }
    });

    if (token) {
      dispatch(createGiftProduct(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isCreateComplete]);

  return (
    <div className="gift_product_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        {mattressList.map((productInfo, index) => (
          <GiftProductFormItem
            postalCode={postal_code}
            province={province}
            candidateDeliveryDate={
              productInfo.productID in candidateDeliveryDate
                ? candidateDeliveryDate[productInfo.productID]
                : null
            }
            candidateDeliveryDateEnd={
              productInfo.productID in candidateDeliveryDateEnd
                ? candidateDeliveryDateEnd[productInfo.productID]
                : null
            }
            productInfo={productInfo}
            key={index}
          />
        ))}
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default GiftProductCreate;
