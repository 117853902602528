// libs
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { getGiftProductDeliveryDate } from "redux/actions/gift";

// constants
import { mattressKing } from "constants/products";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const GiftProductFormItem = (props) => {
  const {
    province,
    postalCode,
    productInfo,
    candidateDeliveryDate,
    candidateDeliveryDateEnd,
  } = props;

  const FIELDS = {
    quantity: "quantity",
    shipmentID: "shipment_id",
    trackingCode: "tracking_code",
    deliveryDate: "delivery_date",
    deliveryTime: "delivery_time",
  };

  const token = useSelector((state) => state.auth.token);

  const [selectedProductQuantity, setSelectedProductQuantity] = useState(0);

  const dispatch = useDispatch();

  const deliveryDateInputRef = useRef();
  const deliveryTimeInputRef = useRef();

  const isKing = mattressKing.productID === productInfo.productID;

  const resetDeliveryDateTime = () => {
    if (deliveryTimeInputRef.current && deliveryDateInputRef.current) {
      deliveryDateInputRef.current.value = "";
      deliveryTimeInputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (selectedProductQuantity !== 0) {
      const data = {
        postal_code: postalCode,
        openlogi_product_id: productInfo.productID,
        quantity: selectedProductQuantity,
      };
      dispatch(getGiftProductDeliveryDate(token, data));
    }
  }, [selectedProductQuantity]);

  return (
    <table className="product_item">
      <tbody>
        <tr>
          <th>{productInfo.name}</th>
          <td>
            <select
              className="design_select"
              name={productInfo.productID + "_" + FIELDS.quantity}
              onChange={(e) => {
                setSelectedProductQuantity(parseInt(e.target.value));
                resetDeliveryDateTime();
              }}
              required
            >
              {[...Array(8)].map((_, index) => (
                <option value={index} key={index}>
                  {index}個
                </option>
              ))}
            </select>
          </td>
        </tr>
        {selectedProductQuantity !== 0 && (
          <>
            <tr className="delivery_date_input">
              <th>配送日</th>
              <td>
                <input
                  className="design_input"
                  type="date"
                  name={productInfo.productID + "_" + FIELDS.deliveryDate}
                  onKeyDown={(e) => e.preventDefault()}
                  style={{ width: "50%" }}
                  min={
                    candidateDeliveryDate &&
                    dateToString(candidateDeliveryDate, "yyyy-MM-dd")
                  }
                  max={
                    candidateDeliveryDateEnd &&
                    dateToString(candidateDeliveryDateEnd, "yyyy-MM-dd")
                  }
                  disabled={province === "沖縄県"}
                  ref={deliveryDateInputRef}
                />
              </td>
            </tr>
            <tr className="delivery_time_input">
              <th>配送時間帯</th>
              <td>
                <select
                  className="design_select"
                  name={productInfo.productID + "_" + FIELDS.deliveryTime}
                  disabled={province === "沖縄県"}
                  ref={deliveryTimeInputRef}
                >
                  <option value="">時間希望なし</option>
                  <option value="AM">AM</option>
                  {isKing ? (
                    <>
                      <option value="12-18">12-18時</option>
                      <option value="18-21">18-21時</option>
                    </>
                  ) : (
                    <>
                      <option value="14">14-16時</option>
                      <option value="16">16-18時</option>
                      <option value="18">18-20時</option>
                      <option value="19">19-21時</option>
                    </>
                  )}
                </select>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default GiftProductFormItem;
