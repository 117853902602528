// libs
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateGiftProduct, clearStatus } from "redux/actions/gift";

// utils
import { dateToString } from "utils/date";

// constants
import { mattressKing } from "constants/products";

// style
import "./style.scss";

const GiftProductEdit = (props) => {
  const {
    id,
    product_id,
    status,
    name,
    candidate_delivery_date,
    candidate_delivery_date_end,
    delivery_date,
    delivery_time,
  } = props.product;
  const province = props.province;

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.gift.isProcessing);
  const isUpdateComplete = useSelector((state) => state.gift.isUpdateComplete);
  const [isPreferredNone, setIsPreferredNone] = useState(!delivery_date);
  const [isSubmitEnabled, setSubmitEnabled] = useState(false);

  const isKing = mattressKing.productID === product_id;

  const dispatch = useDispatch();

  const FIELDS = {
    deliveryDate: "delivery_date",
    deliveryTime: "delivery_time",
    shipmentID: "shipment_id",
    trackingCode: "tracking_code",
  };

  let elements = {};

  const changeGift = () => {
    const data = {
      nell_product_id: id,
      delivery_date: isPreferredNone ? "" : elements[FIELDS.deliveryDate].value,
      delivery_time: isPreferredNone ? "" : elements[FIELDS.deliveryTime].value,
    };

    if (token) {
      dispatch(updateGiftProduct(token, data));
    }
  };

  const handleChange = () => {
    setSubmitEnabled(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeGift();
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="gift_product_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="gift_product_item">
          <tbody>
            <tr>
              <th>商品名</th>
              <td>{name}</td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>{status}</td>
            </tr>
            <tr>
              <th>配送予定日</th>
              <td>
                <input
                  className="design_input"
                  type="date"
                  name={FIELDS.deliveryDate}
                  onKeyDown={(e) => e.preventDefault()}
                  defaultValue={
                    delivery_date && dateToString(delivery_date, "yyyy-MM-dd")
                  }
                  style={{ width: "50%" }}
                  min={
                    candidate_delivery_date &&
                    dateToString(candidate_delivery_date, "yyyy-MM-dd")
                  }
                  max={
                    candidate_delivery_date_end &&
                    dateToString(candidate_delivery_date_end, "yyyy-MM-dd")
                  }
                  disabled={province === "沖縄県" || isPreferredNone}
                />
              </td>
              <td>
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => setIsPreferredNone(e.target.checked)}
                    style={{ marginTop: "1vh" }}
                    defaultChecked={!delivery_date}
                  />
                  希望なし
                </label>
              </td>
            </tr>
            <tr>
              <th>配送予定時間帯</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.deliveryTime}
                  defaultValue={delivery_time}
                  disabled={province === "沖縄県"}
                >
                  <option value="">時間希望なし</option>
                  <option value="AM">AM</option>
                  {isKing ? (
                    <>
                      <option value="12-18">12-18時</option>
                      <option value="18-21">18-21時</option>
                    </>
                  ) : (
                    <>
                      <option value="14">14-16時</option>
                      <option value="16">16-18時</option>
                      <option value="18">18-20時</option>
                      <option value="19">19-21時</option>
                    </>
                  )}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing || !isSubmitEnabled}
          />
        </div>
      </form>
    </div>
  );
};

export default GiftProductEdit;
