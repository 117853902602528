export const dateToString = (date, format) => {
	let dateString = format.toString();
	const dt = new Date(date);
	dateString = dateString.replace(/yyyy/g, dt.getFullYear());
	dateString = dateString.replace(/MM/g, ("0" + (dt.getMonth() + 1)).slice(-2));
	dateString = dateString.replace(/dd/g, ("0" + dt.getDate()).slice(-2));
	dateString = dateString.replace(/HH/g, ("0" + dt.getHours()).slice(-2));
	dateString = dateString.replace(/mm/g, ("0" + dt.getMinutes()).slice(-2));
	dateString = dateString.replace(/ss/g, ("0" + dt.getSeconds()).slice(-2));
	dateString = dateString.replace(
		/SSS/g,
		("00" + dt.getMilliseconds()).slice(-3)
	);
	return dateString;
};

export const parseDeliveryTime = (time) => {
	switch (time) {
		case "AM":
			return "午前中";
		case "14":
			return "14時〜16時";
		case "16":
			return "16時〜18時";
		case "18":
			return "18時〜20時";
		case "19":
			return "19時〜21時";
		case "12-15":
			return "12時〜15時";
		case "15-18":
			return "15時〜18時";
		case "18-21":
			return "18時〜21時";
		case "12-18":
			return "12時〜18時";
		default:
			return "なし";
	}
};