// libs
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import { changeOrderProduct, clearStatus } from "redux/actions/order";

// util
import { dateToString } from "utils/date";

import { mattressKing } from "constants/products";

// style
import "./style.scss";

const ProductEdit = (props) => {
  // params
  const {
    id,
    openlogi_product_id,
    product_name,
    preferred_delivery_date,
    preferred_delivery_time,
    scheduled_delivery_date,
    candidate_delivery_date,
    delivery_status,
  } = props.product;

  const isKing = mattressKing.productID === openlogi_product_id;

  const FIELDS = {
    deliveryDate: "delivery_date",
    deliveryTime: "delivery_time",
  };

  let elements = {};

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.order.isProcessing);
  const isChanged = useSelector((state) => state.order.isChangedProduct);
  const [isPreferredNone, setIsPreferredNone] = useState(
    !preferred_delivery_date
  );

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeProduct();
    }
  };

  const changeProduct = () => {
    const data = {
      product_id: id,
      delivery_date: isPreferredNone ? "" : elements[FIELDS.deliveryDate].value,
      delivery_time: isPreferredNone ? "" : elements[FIELDS.deliveryTime].value,
    };

    if (token) {
      dispatch(changeOrderProduct(token, data));
    }
  };

  useEffect(() => {
    if (isChanged) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isChanged]);

  return (
    <div className="order_product_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>商品名</th>
              <td>{product_name}</td>
            </tr>
            <tr>
              <th>配送希望日</th>
              <td>
                <input
                  className="design_input"
                  type="date"
                  name={FIELDS.deliveryDate}
                  onKeyDown={(e) => e.preventDefault()}
                  defaultValue={
                    preferred_delivery_date &&
                    dateToString(preferred_delivery_date, "yyyy-MM-dd")
                  }
                  min={candidate_delivery_date}
                  disabled={isPreferredNone}
                />
                {isKing ? (
                  <select
                    className="design_select"
                    name={FIELDS.deliveryTime}
                    defaultValue={preferred_delivery_time}
                    style={{ marginLeft: "1vw" }}
                    disabled={isPreferredNone}
                  >
                    <option value="AM">午前中</option>
                    <option value="12-18">12時〜18時</option>
                    <option value="18-21">18時〜21時</option>
                    <option value="">時間指定なし</option>
                  </select>
                ) : (
                  <select
                    className="design_select"
                    name={FIELDS.deliveryTime}
                    defaultValue={preferred_delivery_time}
                    style={{ marginLeft: "1vw" }}
                    disabled={isPreferredNone}
                  >
                    <option value="AM">午前中</option>
                    <option value="14">14時〜16時</option>
                    <option value="16">16時〜18時</option>
                    <option value="18">18時〜20時</option>
                    <option value="19">19時〜21時</option>
                    <option value="">時間指定なし</option>
                  </select>
                )}
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => setIsPreferredNone(e.target.checked)}
                    style={{ marginTop: "1vh" }}
                    defaultChecked={!preferred_delivery_date}
                  />
                  希望なし
                </label>
              </td>
            </tr>
            <tr>
              <th>配送予定日</th>
              <td>{scheduled_delivery_date || "未定"}</td>
            </tr>
            <tr>
              <th>配送状況</th>
              <td>{delivery_status}</td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default ProductEdit;
