import * as types from "../types/gift";

const giftInfoInitialState = {
	id: "",
	name: "",
	postal_code: "",
	province: "",
	city_address_line: "",
	phone_number: "",
	label: "",
	memo: "",
	created_at: "",
	product_list: []
};

const filterInitialState = {
	name: "",
	label: "",
	shipmentId: "",
	sortBy: "created_at",
	sortType: "desc",
};

const initialState = {
	info: giftInfoInitialState,
	giftList: {
		list: [],
		count: 0,
	},
	giftLabelList: [],
	candidateDeliveryDate: {},
	candidateDeliveryDateEnd: {},
	isUpdateComplete: false,
	isCreateComplete: false,
	isCancelComplete: false,
	currentPage: 1,
	isProcessing: false,
	filter: filterInitialState,
	error: null,
};

const gift = (state = initialState, action) => {
	switch (action.type) {
		// ギフト一覧取得
		case types.GET_GIFTS_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_GIFTS_SUCCESS:
			return {
				...state,
				isProcessing: false,
				giftList: {
					list: action.data.gift_list,
					count: action.data.count,
				},
			};
		case types.GET_GIFTS_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		
		// ギフトラベル一覧取得
		case types.GET_GIFT_LABELS_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_GIFT_LABELS_SUCCESS:
			return {
				...state,
				isProcessing: false,
				giftLabelList: action.data.label_list,
			};
		case types.GET_GIFT_LABELS_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// ギフト情報取得
		case types.GET_GIFT_INFO_REQUEST:
			return {
				...state,
				isProcessing: true,
				info: giftInfoInitialState,
			};
		case types.GET_GIFT_INFO_SUCCESS:
			return { ...state, isProcessing: false, info: action.data.gift };
		case types.GET_GIFT_INFO_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// ギフト作成
		case types.CREATE_GIFT_REQUEST:
			return {
				...state,
				isProcessing: true,
				isCreateComplete: false,
				error: null,
			};
		case types.CREATE_GIFT_SUCCESS:
			return { ...state, isProcessing: false, isCreateComplete: true };
		case types.CREATE_GIFT_FAILURE:
			return {
				...state,
				isProcessing: false,
				isCreateComplete: false,
				error: action.error,
			};

		// ギフト編集
		case types.UPDATE_GIFT_REQUEST:
			return {
				...state,
				isProcessing: true,
				isUpdateComplete: false,
				error: null,
			};
		case types.UPDATE_GIFT_SUCCESS:
			return { ...state, isProcessing: false, isUpdateComplete: true };
		case types.UPDATE_GIFT_FAILURE:
			return {
				...state,
				isProcessing: false,
				isUpdateComplete: false,
				error: action.error,
			};
		
		// ギフト商品作成
		case types.CREATE_GIFT_PRODUCT_REQUEST:
			return {
				...state,
				isProcessing: true,
				isCreateComplete: false,
				error: null,
			};
		case types.CREATE_GIFT_PRODUCT_SUCCESS:
			return { ...state, isProcessing: false, isCreateComplete: true };
		case types.CREATE_GIFT_PRODUCT_FAILURE:
			return {
				...state,
				isProcessing: false,
				isCreateComplete: false,
				error: action.error,
			};

		// ギフト商品編集
		case types.UPDATE_GIFT_PRODUCT_REQUEST:
			return {
				...state,
				isProcessing: true,
				isUpdateComplete: false,
				error: null,
			};
		case types.UPDATE_GIFT_PRODUCT_SUCCESS:
			return { ...state, isProcessing: false, isUpdateComplete: true };
		case types.UPDATE_GIFT_PRODUCT_FAILURE:
			return {
				...state,
				isProcessing: false,
				isUpdateComplete: false,
				error: action.error,
			};
		// ギフト商品キャンセル
		case types.CANCEL_GIFT_PRODUCT_REQUEST:
			return {
				...state,
				isProcessing: true,
				isCancelComplete: false,
				error: null,
			};
		case types.CANCEL_GIFT_PRODUCT_SUCCESS:
			return { ...state, isProcessing: false, isCancelComplete: true };
		case types.CANCEL_GIFT_PRODUCT_FAILURE:
			return {
				...state,
				isProcessing: false,
				isCancelComplete: false,
				error: action.error,
			};
		
		// ギフト商品の配送可能日取得
		case types.GET_GIFT_PRODUCT_DELIVERY_DATE_REQUEST:
			return {
				...state,
				isProcessing: true,
			};
		case types.GET_GIFT_PRODUCT_DELIVERY_DATE_SUCCESS:
			return {
				...state, 
				isProcessing: false, 
				candidateDeliveryDate: {
					...state.candidateDeliveryDate,
					[action.requestData.openlogi_product_id]: action.data.candidate_delivery_date,
				},
				candidateDeliveryDateEnd: {
					...state.candidateDeliveryDateEnd,
					[action.requestData.openlogi_product_id]: action.data.candidate_delivery_date_end,
				}
			};
		case types.GET_GIFT_PRODUCT_DELIVERY_DATE_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		

		case types.CLEAR_GIFT_STATUS:
			return { ...state, isUpdateComplete: false, isCreateComplete: false, isCancelComplete: false };
		case types.SET_GIFT_FILTER:
			return { ...state, filter: action.data };
		case types.CLEAR_GIFT_FILTER:
			return { ...state, filter: filterInitialState };
		case types.CLEAR_GIFT_ERROR:
			return { ...state, error: null };
		case types.CHANGE_GIFT_PAGE:
			return { ...state, currentPage: action.page };
		default:
			return state;
	}
};

export default gift;
