// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import {
  updatePermissionGroup,
  permissionGroupClearStatus,
  getPermissionGroups,
} from "redux/actions/adminUser";
import { getMyAccountInfo } from "redux/actions/myAccount";

// utils
import { dateToString } from "utils/date";

// const
import { applicationList } from "constants/application";

// style
import "./style.scss";

const AdminUserPermissionGroupEdit = (props) => {
  // params
  const { id, name, permission_list, created_at } = props.permissionGroup;

  const FIELDS = {
    name: "name",
    permissionGroupId: "permission_group_id",
  };

  let elements = {};

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const permissionGroupIsProcessing = useSelector(
    (state) => state.adminUser.permissionGroupIsProcessing
  );
  const permissionGroupIsUpdateComplete = useSelector(
    (state) => state.adminUser.permissionGroupIsUpdateComplete
  );
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeAdminUser();
    }
  };

  const changeAdminUser = () => {
    const data = {
      permission_group_id: id,
      name: elements[FIELDS.name].value,
      permission_list: [],
    };

    Object.keys(applicationList).forEach((applicationId) => {
      if (applicationList[applicationId].id) {
        data.permission_list.push({
          application_id: applicationList[applicationId].id,
          permission_type:
            elements[
              FIELDS.permission + "_" + applicationList[applicationId].id
            ].value,
        });
      }
    });

    if (token) {
      dispatch(updatePermissionGroup(token, data));
    }
  };

  const getDefaultType = (applicationId) => {
    const targetPermission = permission_list.find(
      (permission) => permission.application_id === applicationId
    );
    const permissionType = targetPermission ? targetPermission.type : "";
    return permissionType;
  };

  useEffect(() => {
    if (permissionGroupIsUpdateComplete) {
      window.alert("変更しました");
      dispatch(permissionGroupClearStatus());
      props.close();
      if (myAccount.permission_group) {
        if (myAccount.permission_group.id === id) {
          dispatch(getMyAccountInfo(token));
        }
      }
    }
  }, [permissionGroupIsUpdateComplete]);

  useEffect(() => {
    dispatch(getPermissionGroups(token));
  }, []);

  return (
    <div className="admin_user_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="admin_user_item">
          <tbody>
            <tr>
              <th>名前</th>
              <td>
                <input
                  className="design_input"
                  name={FIELDS.name}
                  type="text"
                  defaultValue={name}
                />
              </td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
            <tr>
              <th>権限</th>
              <td>
                <table>
                  <tbody>
                    {Object.keys(applicationList).map(
                      (applicationId, index) =>
                        applicationList[applicationId].id && (
                          <ApplicationPermissionItem
                            applicationName={
                              applicationList[applicationId].applicationName
                            }
                            id={applicationId}
                            FIELDS={FIELDS}
                            key={index}
                            defaultType={getDefaultType(applicationId)}
                          />
                        )
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={permissionGroupIsProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default AdminUserPermissionGroupEdit;

const ApplicationPermissionItem = (props) => {
  const { id, applicationName, FIELDS, defaultType } = props;

  return (
    <tr className="radio_button_container">
      <th>{applicationName}</th>
      <td>
        <label className="radio_button">
          <input
            name={FIELDS.permission + "_" + id}
            type="radio"
            value=""
            defaultChecked={defaultType === ""}
          />
          なし
        </label>
      </td>
      <td>
        <label className="radio_button">
          <input
            name={FIELDS.permission + "_" + id}
            type="radio"
            value="read"
            defaultChecked={defaultType === "read"}
          />
          読み込み
        </label>
      </td>
      <td>
        <label className="radio_button">
          <input
            name={FIELDS.permission + "_" + id}
            type="radio"
            value="write"
            defaultChecked={defaultType === "write"}
          />
          読み込み・書き込み
        </label>
      </td>
    </tr>
  );
};
