const isDevelopment = process.env.REACT_APP_MODE === "development";

export const mattressSingle = {
  productID: isDevelopment ? "GB711-I000001" : "EG399-I000001",
  price: 75000,
  name: "NELL マットレス シングル",
  size: "95×195×21",
  coil: 1173,
  weight: 19.1,
  width: 95,
  length: 195,
  height: 21,
};

export const mattressSemiDouble = {
  productID: isDevelopment ? "GB711-I000002" : "EG399-I000002",
  price: 90000,
  name: "NELL マットレス セミダブル",
  size: "120×195×21",
  coil: 1479,
  weight: 23.38,
  width: 120,
  length: 195,
  height: 21,
};

export const mattressDouble = {
  productID: isDevelopment ? "GB711-I000003" : "EG399-I000003",
  price: 105000,
  name: "NELL マットレス ダブル",
  size: "140×195×21",
  coil: 1734,
  weight: 27.08,
  width: 140,
  length: 195,
  height: 21,
};

export const mattressQueen = {
  productID: isDevelopment ? "GB711-I000005" : "EG399-I000004",
  price: 130000,
  name: "NELL マットレス クイーン",
  size: "160×195×21",
  coil: 1989,
  weight: 34.0,
  width: 160,
  length: 195,
  height: 21,
};

export const mattressKing = {
  productID: isDevelopment ? "GB711-I000004" : "EG399-I000005",
  price: 150000,
  name: "NELL マットレス キング",
  size: "190×195×21",
  coil: 2397,
  weight: 39.7,
  width: 190,
  length: 195,
  height: 21,
};

export const boxSheetsOffWhiteSingle = {
  productID: "NBS-OW-S",
  price: 6000,
  name: "NELLボックスシーツ_オフホワイト（シングル）",
  size: "82x180x37",
  coil: 0,
  weight: null,
  width: 82,
  length: 180,
  height: 37,
};

export const boxSheetsOffWhiteSemiDouble = {
  productID: "NBS-OW-SD",
  price: 7000,
  name: "NELLボックスシーツ_オフホワイト（セミダブル）",
  size: "106x180x37",
  coil: 0,
  weight: null,
  width: 106,
  length: 180,
  height: 37,
};

export const boxSheetsOffWhiteDouble = {
  productID: "NBS-OW-D",
  price: 8000,
  name: "NELLボックスシーツ_オフホワイト（ダブル）",
  size: "126x180x37",
  coil: 0,
  weight: null,
  width: 126,
  length: 180,
  height: 37,
};

export const boxSheetsOffWhiteQueen = {
  productID: "NBS-OW-Q",
  price: 9000,
  name: "NELLボックスシーツ_オフホワイト（クイーン）",
  size: "140x180x37",
  coil: 0,
  weight: null,
  width: 140,
  length: 180,
  height: 37,
};

export const boxSheetsOffWhiteKing = {
  productID: "NBS-OW-K",
  price: 10000,
  name: "NELLボックスシーツ_オフホワイト（キング）",
  size: "165x180x37",
  coil: 0,
  weight: null,
  width: 165,
  length: 180,
  height: 37,
};

export const boxSheetsNellGraySingle = {
  productID: "NBS-NG-S",
  price: 6000,
  name: "NELLボックスシーツ_NELLグレー（シングル）",
  size: "82x180x37",
  coil: 0,
  weight: null,
  width: 82,
  length: 180,
  height: 37,
};

export const boxSheetsNellGraySemiDouble = {
  productID: "NBS-NG-SD",
  price: 7000,
  name: "NELLボックスシーツ_NELLグレー（セミダブル）",
  size: "106x180x37",
  coil: 0,
  weight: null,
  width: 106,
  length: 180,
  height: 37,
};

export const boxSheetsNellGrayDouble = {
  productID: "NBS-NG-D",
  price: 8000,
  name: "NELLボックスシーツ_NELLグレー（ダブル）",
  size: "126x180x37",
  coil: 0,
  weight: null,
  width: 126,
  length: 180,
  height: 37,
};

export const boxSheetsNellGrayQueen = {
  productID: "NBS-NG-Q",
  price: 9000,
  name: "NELLボックスシーツ_NELLグレー（クイーン）",
  size: "140x180x37",
  coil: 0,
  weight: null,
  width: 140,
  length: 180,
  height: 37,
};

export const boxSheetsNellGrayKing = {
  productID: "NBS-NG-K",
  price: 10000,
  name: "NELLボックスシーツ_NELLグレー（キング）",
  size: "165x180x37",
  coil: 0,
  weight: null,
  width: 165,
  length: 180,
  height: 37,
};


export const mattressList = [
  mattressSingle,
  mattressSemiDouble,
  mattressDouble,
  mattressQueen,
  mattressKing,
];

export const boxSheetsList = [
  boxSheetsOffWhiteSingle,
  boxSheetsOffWhiteSemiDouble,
  boxSheetsOffWhiteDouble,
  boxSheetsOffWhiteQueen,
  boxSheetsOffWhiteKing,
  boxSheetsNellGraySingle,
  boxSheetsNellGraySemiDouble,
  boxSheetsNellGrayDouble,
  boxSheetsNellGrayQueen,
  boxSheetsNellGrayKing,
];

export const productList = [...mattressList, ...boxSheetsList];

export const getProductByID = (id) => {
  return productList.find((product) => product.productID === id);
};
