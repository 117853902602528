import { combineReducers } from "redux";

import auth from "./auth";
import user from "./user";
import order from "./order";
import product from "./product";
import coupon from "./coupon";
import review from "./review";
import news from "./news";
import stock from "./stock";
import notice from "./notice";
import faq from "./faq";
import serverStatus from "./serverStatus";
import sale from "./sale";
import ugc from "./ugc";
import gift from "./gift";
import businessContact from "./businessContact";
import adminUser from "./adminUser";
import myAccount from "./myAccount";

export const rootReducer = combineReducers({
  auth,
  user,
  order,
  product,
  coupon,
  review,
  news,
  stock,
  notice,
  faq,
  serverStatus,
  sale,
  ugc,
  gift,
  businessContact,
  adminUser,
  myAccount,
});
