// libs
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateCoupon, clearStatus } from "redux/actions/coupon";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const ProductEdit = (props) => {
  const {
    code,
    name,
    discount_rate,
    status,
    expiration_date,
    start_date,
    created_at,
  } = props.coupon;

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.coupon.isProcessing);
  const isUpdateComplete = useSelector(
    (state) => state.coupon.isUpdateComplete
  );

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [expirationDateIsLimitNone, setExpirationDateIsLimitNone] = useState(
    !expiration_date
  );
  const [startDateIsLimitNone, setStartDateIsLimitNone] = useState(!start_date);

  const dispatch = useDispatch();

  const FIELDS = {
    name: "name",
    discountRate: "discount_rate",
    status: "status",
    expirationDate: "expiration_date",
    startDate: "start_date",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every((field) => {
      if (field === FIELDS.expirationDate) {
        console.log(elements[field].value);
        return expirationDateIsLimitNone ? true : elements[field].value;
      }
      if (field === FIELDS.startDate) {
        return startDateIsLimitNone ? true : elements[field].value;
      }
      return elements[field].value;
    });

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const changeCoupon = () => {
    const data = {
      coupon_code: code,
      name: elements[FIELDS.name].value,
      discount_rate: elements[FIELDS.discountRate].value,
      status: elements[FIELDS.status].value,
      expiration_date: expirationDateIsLimitNone
        ? ""
        : elements[FIELDS.expirationDate].value + ":00",
      start_date: startDateIsLimitNone
        ? ""
        : elements[FIELDS.startDate].value + ":00",
    };

    if (token) {
      dispatch(updateCoupon(token, data));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeCoupon();
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isUpdateComplete]);

  useEffect(() => {
    setSubmitEnabled();
  }, [startDateIsLimitNone, expirationDateIsLimitNone]);

  return (
    <div className="coupon_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>クーポンクード</th>
              <td>{code}</td>
            </tr>
            <tr>
              <th>クーポン名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.name}
                  defaultValue={name}
                />
              </td>
            </tr>
            <tr>
              <th>割引率</th>
              <td>
                <input
                  className="design_input"
                  type="number"
                  name={FIELDS.discountRate}
                  defaultValue={discount_rate}
                  min="0"
                  max="100"
                />
              </td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.status}
                  defaultValue={status}
                >
                  <option value={true}>使用可能</option>
                  <option value={false}>使用不可</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>使用開始日時</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.startDate}
                  defaultValue={
                    start_date && dateToString(start_date, "yyyy-MM-ddTHH:mm")
                  }
                  disabled={startDateIsLimitNone}
                />
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => setStartDateIsLimitNone(e.target.checked)}
                    style={{ marginTop: "1vh" }}
                    defaultChecked={!start_date}
                  />
                  設定なし
                </label>
              </td>
            </tr>
            <tr>
              <th>使用期限</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.expirationDate}
                  defaultValue={
                    expiration_date &&
                    dateToString(expiration_date, "yyyy-MM-ddTHH:mm")
                  }
                  disabled={expirationDateIsLimitNone}
                />
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setExpirationDateIsLimitNone(e.target.checked)
                    }
                    style={{ marginTop: "1vh" }}
                    defaultChecked={!expiration_date}
                  />
                  期限なし
                </label>
              </td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing || !isSubmitEnabled}
          />
        </div>
      </form>
    </div>
  );
};

export default ProductEdit;
