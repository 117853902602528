import * as types from "../types/review";

const reviewInfoInitialState = {
  id: "",
  title: "",
  score: "",
  name: "",
  text: "",
  created_at: "",
  is_open: "",
  is_open_lp: "",
};

const filterInitialState = {
  name: "",
  score: "",
  isOpenLP: "",
  isOpenBR: "",
  isChecked: "",
  sortBy: "created_at",
  sortType: "desc",
};

const initialState = {
  filter: filterInitialState,
  info: reviewInfoInitialState,
  reviewList: {
    list: [],
    count: 0,
  },
  isProcessing: false,
  currentPage: 1,
  isUpdateComplete: false,
  isCreateComplete: false,
  error: null,
};

const review = (state = initialState, action) => {
  switch (action.type) {
    // レビュー一覧取得
    case types.GET_REVIEWS_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        reviewList: {
          list: action.data.review_list,
          count: action.data.count,
        },
      };
    case types.GET_REVIEWS_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // レビュー情報取得
    case types.GET_REVIEW_INFO_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_REVIEW_INFO_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        info: action.data.review,
      };
    case types.GET_REVIEW_INFO_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // レビュー作成
    case types.CREATE_REVIEW_REQUEST:
      return { ...state, isProcessing: true };
    case types.CREATE_REVIEW_SUCCESS:
      return { ...state, isProcessing: false, isCreateComplete: true };
    case types.CREATE_REVIEW_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // レビュー編集
    case types.UPDATE_REVIEW_REQUEST:
      return { ...state, isProcessing: true };
    case types.UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        info: action.data.review,
        isProcessing: false,
        isUpdateComplete: true,
      };
    case types.UPDATE_REVIEW_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    case types.CLEAR_REVIEW_STATUS:
      return { ...state, isUpdateComplete: false, isCreateComplete: false };
    case types.SET_REVIEW_FILTER:
      return { ...state, filter: action.data };
    case types.CLEAR_REVIEW_FILTER:
      return { ...state, filter: filterInitialState };
    case types.CLEAR_REVIEW_ERROR:
      return { ...state, error: null };
    case types.CHANGE_REVIEW_PAGE:
      return { ...state, currentPage: action.page };
    default:
      return state;
  }
};

export default review;
